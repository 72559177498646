import {initializeApp} from "firebase/app";
import {getStorage, ref} from "firebase/storage";
import {getDatabase, ref as dRef} from "firebase/database";
import {getAuth} from "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};


const firebaseApp = initializeApp(firebaseConfig);



const storage = getStorage(firebaseApp);
const storageRef = ref(storage);


const database = getDatabase(firebaseApp);
const dbRef = dRef(database);



const auth = getAuth();


export {
    firebaseApp,
    storage,
    storageRef,
    database,
    dbRef,
    auth
};